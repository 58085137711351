import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/categories';
const local_URL = 'http://localhost:3001/api/categories';
/* eslint-enable no-unused-vars */
const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

// Fetch all categories
export const getCategories = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

// Fetch a single category by ID
export const getCategory = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new category
export const createCategory = async (category) => {
  const response = await api.post(BASE_URL, category);
  return response.data;
};

// Update a category
export const updateCategory = async (id, category) => {
  const response = await api.put(`${BASE_URL}/${id}`, category);
  return response.data;
};

// Delete a category
export const deleteCategory = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};
