import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/menu-items'; // use for the remote backend
const local_URL = 'http://localhost:3001/api/menu-items'; // use for local backend
/* eslint-enable no-unused-vars */
const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const getMenuItems = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

export const getMenuItem = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

export const createMenuItem = async (menuItem) => {
  const response = await api.post(BASE_URL, menuItem);
  return response.data;
};

export const updateMenuItem = async (id, menuItem) => {
  const response = await api.put(BASE_URL + `/${id}`, menuItem);
  return response.data;
};

export const deleteMenuItem = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};
