import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SearchInput = ({ searchTerm, onSearch }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClear = () => {
    onSearch(''); // Reset the search term to empty string
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="חיפוש..."
      value={searchTerm}
      onChange={(e) => onSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '0',
            }}
          >
            <SearchIcon
              sx={{ margin: '0 0 0 10px', paddingRight: '0' }}
              color={theme.palette.mode === 'dark' ? 'primary' : 'action'}
            />
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment
            position="end"
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingRight: '0',
            }}
          >
            <IconButton
              aria-label="clear search"
              onClick={handleClear}
              sx={{ margin: '0' }}
              edge="end"
              size="small"
            >
              <ClearIcon fontSize="small" sx={{ margin: '0 10px 0 0' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth={isMobile}
      sx={{
        direction: 'rtl',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.background.paper
            : 'white',
        borderRadius: 1,
        '& .MuiOutlinedInput-root': {
          padding: '10px 0', // Remove padding
          '& fieldset': {
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.divider
                : 'transparent',
          },
          '&:hover fieldset': {
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.primary.main
                : 'transparent',
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
        '& .MuiInputBase-input': {
          padding: '0 10px', // Adjust padding as needed
          color: theme.palette.text.primary,
        },
        '& .MuiInputBase-input::placeholder': {
          color: theme.palette.text.secondary,
          opacity: 1,
        },
      }}
    />
  );
};

export default SearchInput;
