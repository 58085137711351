import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMenuItems,
  createMenuItem,
  updateMenuItem,
  deleteMenuItem,
} from '../../api/menuApi';

// Fetch all menu items
export const fetchManagerMenuItems = createAsyncThunk(
  'managerMenu/fetchManagerMenuItems',
  async () => {
    const response = await getMenuItems();
    return response;
  }
);

// Create a new menu item
export const addMenuItem = createAsyncThunk(
  'managerMenu/addMenuItem',
  async (newItem) => {
    const response = await createMenuItem(newItem);
    return response;
  }
);

// Update an existing menu item
export const editMenuItem = createAsyncThunk(
  'managerMenu/editMenuItem',
  async ({ id, updatedItem }) => {
    const formData = updatedItem;
    const response = await updateMenuItem(id, formData);
    return response;
  }
);

// Delete a menu item
export const removeMenuItem = createAsyncThunk(
  'managerMenu/removeMenuItem',
  async (id) => {
    const response = await deleteMenuItem(id);
    return response._id;
  }
);
