import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getFilters,
  createFilter,
  updateFilter,
  deleteFilter,
} from '../../api/filterApi';

// Thunk to fetch all filters
export const fetchFilters = createAsyncThunk(
  'filters/fetchFilters', // Action type prefix
  async (_, { rejectWithValue }) => {
    try {
      const filters = await getFilters(); // Call the API to get all filters
      return filters; // Return the filters data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to add a new filter
export const addFilter = createAsyncThunk(
  'filters/addFilter', // Action type prefix
  async (filterData, { rejectWithValue }) => {
    try {
      console.log('filterData:', filterData);
      const newFilter = await createFilter(filterData); // Call the API to create a new filter
      return newFilter; // Return the new filter data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to update a filter
export const updateFilterThunk = createAsyncThunk(
  'filters/updateFilter', // Action type prefix
  async ({ id, updatedFilter }, { rejectWithValue }) => {
    try {
      const updated = await updateFilter(id, updatedFilter); // Call the API to update the filter
      return updated; // Return the updated filter data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);

// Thunk to delete a filter
export const deleteFilterThunk = createAsyncThunk(
  'filters/deleteFilter', // Action type prefix
  async (id, { rejectWithValue }) => {
    try {
      await deleteFilter(id); // Call the API to delete the filter
      return id; // Return the id of the deleted filter
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Handle errors
    }
  }
);
