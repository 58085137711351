import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/configuration';
const local_URL = 'http://localhost:3001/api/configuration';
/* eslint-enable no-unused-vars */

const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

// Fetch all configurations
export const getConfigurations = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

// Fetch a single configuration by ID
export const getConfiguration = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new configuration
export const createConfiguration = async (configuration) => {
  const response = await api.post(BASE_URL, configuration);
  return response.data;
};

// Update a configuration
export const updateConfiguration = async (id, configuration) => {
  const response = await api.put(BASE_URL + `/${id}`, configuration);
  return response.data;
};

// Delete a configuration
export const deleteConfiguration = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};