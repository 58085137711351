import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfiguration } from '../features/configuration/configurationSlice';
import { fetchConfiguration } from '../features/configuration/configurationThunk';
import { selectWorkingHours } from '../features/workingHours/workingHoursSlice';
import { fetchWorkingHours } from '../features/workingHours/workingHoursThunk';

export const useInitialData = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const configurations = useSelector(selectConfiguration);
  const workingHours = useSelector(selectWorkingHours);

  useEffect(() => {
    const fetchInitialData = async () => {
      setError(null);
      setIsLoading(true);

      try {
        // Create an array to store our fetch promises
        const fetchPromises = [];

        if (!configurations || configurations.length === 0) {
          fetchPromises.push(dispatch(fetchConfiguration()).unwrap());
        }

        if (!workingHours || workingHours.length === 0) {
          fetchPromises.push(dispatch(fetchWorkingHours()).unwrap());
        }

        // If we have any data to fetch, do it
        if (fetchPromises.length > 0) {
          await Promise.all(fetchPromises);
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [dispatch, configurations, workingHours]);

  return {
    isLoading,
    error,
    isConfigLoaded: configurations && configurations.length > 0,
    isWorkingHoursLoaded: workingHours && workingHours.length > 0,
    configurations,
    workingHours,
  };
};
