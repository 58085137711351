import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { fetchCategories, updateCategoryThunk } from './CategoryThunk';

export const categorySlice = createSlice({
  name: 'categories',
  initialState: {
    items: [], // Categories will be stored here
    status: 'idle', // Status of the request: idle, loading, succeeded, failed
    error: null, // If there's an error, it will be stored here
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload; // Update state with the fetched categories
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateCategoryThunk.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload; // Update state with the updated categories
      })
      .addCase(updateCategoryThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Basic selectors
const selectCategoriesState = (state) => state.categories;

// Memoized selectors
export const selectCategories = createSelector(
  [selectCategoriesState],
  (categoriesState) => categoriesState.items
);

export const selectCategoryStatus = createSelector(
  [selectCategoriesState],
  (categoriesState) => categoriesState.status
);

export default categorySlice.reducer;