import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/auth';
const local_URL = 'http://localhost:3001/api/auth';
/* eslint-enable no-unused-vars */
const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

export const login = async (credentials) => {
  const response = await api.post('/login', credentials);
  return response.data;
};

export const register = async (userData) => {
  const response = await api.post('/register', userData);
  return response.data;
};

export const logout = async () => {
  const response = await api.post(`/logout`);
  return response.data;
};

export const checkAuth = async () => {
  const response = await api.get('/check');
  return response.data;
};

export const getProfile = async () => {
  const response = await api.get('/me');
  return response.data;
};

export const updateProfile = async (userId, userData) => {
  const response = await api.put(`/profile/${userId}`, userData);
  return response.data;
};

export const handleError = (error) => {
  if (error.response) {
    throw new Error(error.response.data.message || 'Request failed');
  }
  throw error;
};