import React from 'react';
import { Box, useTheme } from '@mui/material';
import MenuItemsDashboard from '../components/dashboardComponents/MenuItemsDashboard.js';
import DashboardNavbar from '../components/dashboardComponents/DashboardNavbar.js';

const ManagerMenuDashboard = ({ toggleTheme }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DashboardNavbar />
      <Box flexGrow={1}>
        <MenuItemsDashboard />
      </Box>
    </Box>
  );
};

export default ManagerMenuDashboard;
