import { FormControl, MenuItem, Select, Typography } from '@mui/material';

const CategoryDropdown = ({
  categories,
  selectedCategory,
  onCategoryChange,
}) => {
  return (
    <FormControl fullWidth style={{ marginTop: '1rem', direction: 'rtl' }}>
      <Typography variant="navbar" mb={1}>
        {' קטגוריית מוצר'}
      </Typography>
      <Select
        value={selectedCategory || ''}
        onChange={(e) => onCategoryChange(e.target.value)}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em>בחר קטגוריה</em>;
          }
          const selectedCategoryObject = categories.find(
            (category) => category._id === selected
          );
          return selectedCategoryObject ? (
            selectedCategoryObject.name
          ) : (
            <em>בחר קטגוריה</em>
          );
        }}
        sx={{
          '& .MuiSelect-select': {
            textAlign: 'right',
            paddingRight: '0rem',
          },
          '& .MuiSelect-icon': {
            left: '0.5rem',
            right: 'unset',
          },
          '& .MuiOutlinedInput-input': {
            padding: '1rem !important',
          },
        }}
      >
        <MenuItem dir="rtl" value="" disabled>
          בחר קטגוריה
        </MenuItem>
        {categories.map((category) => (
          <MenuItem dir="rtl" key={category._id} value={category._id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CategoryDropdown;
