import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Paper,
  Portal,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  DragIndicator,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  RestartAlt as RestartAltIcon,
  AccessibilityNew as AccessibilityNewIcon,
  Contrast as ContrastIcon,
  HideImage as HideImageIcon,
  TouchApp as TouchAppIcon,
  DarkMode as DarkModeIcon,
  FormatColorReset as FormatColorResetIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import './Accessibility.css';
import { deleteCookie } from '../utils/cookies';
import { useSelector } from 'react-redux';
import { selectConfiguration } from '../features/configuration/configurationSlice';

// Function to determine initial dark mode state - only used for reset
const getDarkModeFromConfig = (config, currentTime = null) => {
  switch (config) {
    case 0: // Light mode
      return false;
    case 1: // Dark mode
      return true;
    case 2: // Time-based
      return currentTime; // Pass the current time-based state
    case 3: // System preference
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    default:
      return false;
  }
};

function PaperComponent(props) {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleStop = (e, data) => {
    setPosition({ x: data.x, y: data.y });
  };

  return (
    <Draggable
      position={position}
      onStop={handleStop}
      cancel={
        '[class*="MuiDialogContent-root"], [class*="MuiDialogActions-root"]'
      }
    >
      <Paper {...props} />
    </Draggable>
  );
}

const defaultSettings = {
  highContrast: false,
  fontSize: 16,
  hideImages: false,
  highlightButtons: false,
  darkMode: false,
  grayscale: false,
  underlineLinks: false,
  zoomLevel: 100,
};

const AccessibilityButton = ({
  toggleTheme,
  setThemeWithoutCookie,
  isDarkMode,
  darkModeConfig,
  isNightTime, // Add this new prop
}) => {
  const configurations = useSelector(selectConfiguration);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState({
    ...defaultSettings,
    darkMode: isDarkMode,
  });
  const isMobile = window.innerWidth <= 600;

  const handleSettingChange = (setting, value) => {
    setSettings((prev) => ({ ...prev, [setting]: value }));
  };

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDarkModeToggle = () => {
    toggleTheme();
  };

  // Update the reset settings function
  const resetSettings = () => {
    // Remove dark mode cookie
    deleteCookie('darkMode');

    // Get the default state based on configuration, passing current time-based state
    const configDefaultMode = getDarkModeFromConfig(
      configurations?.[0]?.darkMode,
      isNightTime
    );

    // Always update the theme state immediately
    setThemeWithoutCookie(configDefaultMode);

    // Reset other accessibility settings
    setSettings(defaultSettings);
  };

  useEffect(() => {
    document.documentElement.style.fontSize = `${settings.fontSize}px`;
    document.body.style.lineHeight = 'normal';
    document.body.style.zoom = `${settings.zoomLevel}%`;
    document.body.classList.toggle('high-contrast', settings.highContrast);
    document.body.classList.toggle('hide-images', settings.hideImages);
    document.body.classList.toggle(
      'highlight-buttons',
      settings.highlightButtons
    );
    document.body.classList.toggle('grayscale', settings.grayscale);
    document.body.classList.toggle('underline-links', settings.underlineLinks);
  }, [settings]);

  const AccessibilityCard = ({ title, icon: Icon, isActive, onClick }) => (
    <Paper
      elevation={isActive ? 6 : 1}
      onClick={onClick}
      sx={{
        p: 2,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        bgcolor: isActive ? 'primary.light' : 'background.paper',
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: 'action.hover',
        },
        '&:focus': {
          bgcolor: isActive ? 'primary.light' : 'background.paper',
          outline: 'none',
        },
        '&:active': {
          bgcolor: isActive ? 'primary.light' : 'background.paper',
        },
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          bgcolor: isActive ? 'primary.main' : 'action.selected',
          color: isActive ? 'common.white' : 'text.primary',
        }}
      >
        <Icon />
      </Box>
      <Typography align="center" variant="body2">
        {title}
      </Typography>
    </Paper>
  );

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        style={{
          position: 'fixed',
          top: '90px',
          left: '-24px',
          borderRadius: '0 22% 22% 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0.5rem 0.6rem',
          zIndex: '1111',
        }}
      >
        <AccessibilityNewIcon />
      </Button>

      <Portal>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              margin: isMobile ? '16px' : '32px',
              zIndex: 9999,
              position: 'relative',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '0.5rem',
            }}
          >
            <DragIndicator className="drag-icon" />
          </Box>
          <DialogTitle
            style={{
              cursor: 'move',
              textAlign: 'center',
              padding: '0 0 0.5rem 0',
            }}
            id="draggable-dialog-title"
          >
            אפשרויות נגישות
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              overflowY: 'auto',
              maxHeight: '70vh',
              direction: 'rtl',
              padding: '24px',
            }}
          >
            {/* Accessibility Options Grid */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2,
              }}
            >
              <AccessibilityCard
                title="ניגודיות גבוהה"
                icon={ContrastIcon}
                isActive={settings.highContrast}
                onClick={() =>
                  handleSettingChange('highContrast', !settings.highContrast)
                }
              />

              <AccessibilityCard
                title="הסתר תמונות"
                icon={HideImageIcon}
                isActive={settings.hideImages}
                onClick={() =>
                  handleSettingChange('hideImages', !settings.hideImages)
                }
              />

              <AccessibilityCard
                title="הדגש כפתורים"
                icon={TouchAppIcon}
                isActive={settings.highlightButtons}
                onClick={() =>
                  handleSettingChange(
                    'highlightButtons',
                    !settings.highlightButtons
                  )
                }
              />

              <AccessibilityCard
                title="מצב כהה"
                icon={DarkModeIcon}
                isActive={isDarkMode} // Use the prop instead of local state
                onClick={handleDarkModeToggle}
              />

              <AccessibilityCard
                title="גווני אפור"
                icon={FormatColorResetIcon}
                isActive={settings.grayscale}
                onClick={() =>
                  handleSettingChange('grayscale', !settings.grayscale)
                }
              />

              <AccessibilityCard
                title="הדגש קישורים"
                icon={LinkIcon}
                isActive={settings.underlineLinks}
                onClick={() =>
                  handleSettingChange(
                    'underlineLinks',
                    !settings.underlineLinks
                  )
                }
              />
            </Box>
            {/* Font Size Control */}
            <Box>
              <Typography
                id="font-size-control"
                gutterBottom
                textAlign="center"
              >
                גודל גופן
              </Typography>
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px',
                  borderRadius: 2,
                  bgcolor: 'background.paper',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <Button
                  onClick={() =>
                    handleSettingChange(
                      'fontSize',
                      Math.max(settings.fontSize - 1, 12)
                    )
                  }
                  aria-label="הקטן גופן"
                >
                  <RemoveIcon />
                </Button>
                <Typography sx={{ mx: 2, width: '80px', textAlign: 'center' }}>
                  {settings.fontSize}px
                </Typography>
                <Button
                  onClick={() =>
                    handleSettingChange(
                      'fontSize',
                      Math.min(settings.fontSize + 1, 20)
                    )
                  }
                  aria-label="הגדל גופן"
                >
                  <AddIcon />
                </Button>
              </Paper>
            </Box>

            {/* Zoom Control */}
            <Box>
              <Typography id="zoom-control" gutterBottom textAlign="center">
                הגדלת מסך
              </Typography>
              <Paper
                elevation={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px',
                  borderRadius: 2,
                  bgcolor: 'background.paper',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                <Button
                  onClick={() =>
                    handleSettingChange(
                      'zoomLevel',
                      Math.max(settings.zoomLevel - 10, 70)
                    )
                  }
                >
                  <ZoomOutIcon />
                </Button>
                <Typography
                  sx={{ mx: 2, minWidth: '60px', textAlign: 'center' }}
                >
                  {settings.zoomLevel}%
                </Typography>
                <Button
                  onClick={() =>
                    handleSettingChange(
                      'zoomLevel',
                      Math.min(settings.zoomLevel + 10, 130)
                    )
                  }
                >
                  <ZoomInIcon />
                </Button>
              </Paper>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'space-between',
              padding: '16px',
              zIndex: 1100, // Increase z-index for mobile
              touchAction: 'manipulation',
            }}
          >
            <Button
              type="button"
              onClick={() => {
                console.log('Close button clicked');
                handleClose();
              }}
              sx={{
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'primary.dark'
                    : 'primary.main',
                color:
                  theme.palette.mode === 'dark'
                    ? 'primary.contrastText'
                    : 'common.white',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'primary.main'
                      : 'primary.dark',
                },
                '&:focus': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'primary.dark'
                      : 'primary.main',
                  outline: 'none',
                },
                touchAction: 'manipulation',
              }}
            >
              סגור
            </Button>
            <Button
              type="button"
              onClick={() => {
                resetSettings();
              }}
              sx={{
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'primary.dark'
                    : 'primary.main',
                color:
                  theme.palette.mode === 'dark'
                    ? 'primary.contrastText'
                    : 'common.white',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'primary.main'
                      : 'primary.dark',
                },
                '&:focus': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'primary.dark'
                      : 'primary.main',
                  outline: 'none',
                },
                touchAction: 'manipulation',
              }}
              startIcon={<RestartAltIcon />}
            >
              אפס הגדרות
            </Button>
          </DialogActions>{' '}
        </Dialog>
      </Portal>
    </>
  );
};

export default AccessibilityButton;
