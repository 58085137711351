import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getWorkingHours,
  updateWorkingHour,
  createWorkingHour,
  deleteWorkingHour,
} from '../../api/workingHoursApi';

export const fetchWorkingHours = createAsyncThunk(
  'workingHours/fetchWorkingHours',
  async () => {
    const response = await getWorkingHours();
    return response;
  }
);

export const updateWorkingHours = createAsyncThunk(
  'workingHours/updateWorkingHours',
  async ({ id, workingHour }, { rejectWithValue }) => {
    try {
      const response = await updateWorkingHour(id, workingHour); // Pass ID and data to API
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createWorkingHours = createAsyncThunk(
  'workingHours/createWorkingHours',
  async (workingHours) => {
    const response = await createWorkingHour(workingHours);
    return response;
  }
);

export const deleteWorkingHours = createAsyncThunk(
  'workingHours/deleteWorkingHours',
  async (id) => {
    const response = await deleteWorkingHour(id);
    return response;
  }
);
