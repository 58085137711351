import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  forms: [],
  submissions: [],
  loading: false,
  error: null,
  lastUpdated: null,
};

const formsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    setForms: (state, action) => {
      state.forms = action.payload;
      state.lastUpdated = new Date().toISOString();
    },
    setSubmissions: (state, action) => {
      state.submissions = action.payload;
      state.lastUpdated = new Date().toISOString();
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    removeForm: (state, action) => {
      state.forms = state.forms.filter((form) => form.id !== action.payload);
      // שמירה אוטומטית ל-localStorage
      localStorage.setItem('employee_forms', JSON.stringify(state.forms));
      state.lastUpdated = new Date().toISOString();
    },
    removeLinkedSubmissions: (state, action) => {
      state.submissions = state.submissions.filter(
        (submission) => submission.formId !== action.payload
      );
      // שמירה אוטומטית ל-localStorage
      localStorage.setItem(
        'form_submissions',
        JSON.stringify(state.submissions)
      );
      state.lastUpdated = new Date().toISOString();
    },
    addForm: (state, action) => {
      const newForm = {
        ...action.payload,
        id: action.payload.id || Date.now(), // תוסיף ID אם לא קיים
        createdAt: action.payload.createdAt || new Date().toISOString(),
      };
      state.forms.push(newForm);
      localStorage.setItem('employee_forms', JSON.stringify(state.forms));
      state.lastUpdated = new Date().toISOString();
    },
    addSubmission: (state, action) => {
      const newSubmission = {
        ...action.payload,
        id: action.payload.id || Date.now(),
        submittedAt: action.payload.submittedAt || new Date().toISOString(),
      };
      state.submissions.push(newSubmission);
      localStorage.setItem(
        'form_submissions',
        JSON.stringify(state.submissions)
      );
      state.lastUpdated = new Date().toISOString();
    },
    // העלאת מידע מה-localStorage
    loadFromStorage: (state) => {
      try {
        const savedForms = JSON.parse(
          localStorage.getItem('employee_forms') || '[]'
        );
        const savedSubmissions = JSON.parse(
          localStorage.getItem('form_submissions') || '[]'
        );
        state.forms = savedForms;
        state.submissions = savedSubmissions;
        state.lastUpdated = new Date().toISOString();
      } catch (error) {
        state.error = 'Failed to load data from storage';
      }
    },
    resetState: () => initialState,
  },
});

export const {
  setForms,
  setSubmissions,
  setLoading,
  setError,
  clearError,
  removeForm,
  removeLinkedSubmissions,
  addForm,
  addSubmission,
  loadFromStorage,
  resetState,
} = formsSlice.actions;

// Selectors עם טיפול במקרי קצה
export const selectForms = (state) => state.forms?.forms || [];
export const selectSubmissions = (state) => state.forms?.submissions || [];
export const selectFormsLoading = (state) => state.forms?.loading || false;
export const selectFormsError = (state) => state.forms?.error || null;
export const selectLastUpdated = (state) => state.forms?.lastUpdated;
export const selectFormById = (id) => (state) =>
  state.forms?.forms.find((form) => form.id === id);
export const selectSubmissionsByFormId = (formId) => (state) =>
  state.forms?.submissions.filter(
    (submission) => submission.formId === formId
  ) || [];

export default formsSlice.reducer;
