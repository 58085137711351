export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const setCookie = (name, value, hours) => {
  const expires = new Date();
  expires.setHours(expires.getHours() + hours);
  const secureFlag = window.location.protocol === 'https:' ? 'Secure;' : '';
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/; ${secureFlag}`;
};

export const deleteCookie = (name) => {
  const secureFlag = window.location.protocol === 'https:' ? 'Secure;' : '';
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${secureFlag}`;
};
