import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMenuItems } from '../../api/menuApi';

// Thunk to fetch all menu items
export const fetchMenuItems = createAsyncThunk(
  'menu/fetchMenuItems',
  async () => {
    const response = await getMenuItems();
    // Filter out inactive items
    const activeItems = response.filter(item => item.isActive);
    return activeItems;
  }
);
