import { createSlice } from '@reduxjs/toolkit';
import { fetchMenuItems } from './menuThunks';

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenuItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMenuItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload; // The API data is directly set to items
      })
      .addCase(fetchMenuItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectMenu = (state) => state.menu.items;
export const selectStatus = (state) => state.menu.status;
export default menuSlice.reducer;
