export const COLOR_COMBINATIONS = [
  // כחולים
  {
    id: 19,
    name: 'אינדיגו',
    primary: '#5C6BC0',
    secondary: '#9FA8DA',
  },
  {
    id: 20,
    name: 'כחול כהה',
    primary: '#1373c7',
    secondary: '#BBDEFB',
  },
  {
    id: 1,
    name: 'כחול בהיר',
    primary: '#2196F3',
    secondary: '#90CAF9',
  },
  {
    id: 2,
    name: 'תכלת',
    primary: '#00B0FF',
    secondary: '#64CFFF',
  },
  {
    id: 4,
    name: 'טורקיז ים',
    primary: '#00E5FF',
    secondary: '#00B8D4',
  },
  // טורקיזים
  {
    id: 3,
    name: 'טורקיז',
    primary: '#1eb690',
    secondary: '#42F2CE',
  },
  // ירוקים
  {
    id: 5,
    name: 'ירוק מנטה',
    primary: '#00E676',
    secondary: '#33EA99',
  },
  {
    id: 6,
    name: 'ירוק ליים',
    primary: '#64DD17',
    secondary: '#AEEA00',
  },

  // צהובים עם ניגודיות גבוהה
  {
    id: 7,
    name: 'צהוב חום',
    primary: '#c0a51c', // צהוב כהה יחסית לנראות בלייט מוד
    secondary: '#FFF176',
  },
  {
    id: 8,
    name: 'כתום בהיר',
    primary: '#FFAB00',
    secondary: '#FFD740',
  },

  // כתומים
  {
    id: 9,
    name: 'כתום',
    primary: '#FF6D00',
    secondary: '#FF8F40',
  },
  {
    id: 10,
    name: 'כתום חם',
    primary: '#FF3D00',
    secondary: '#FF7043',
  },

  // אדומים
  {
    id: 11,
    name: 'אדום בהיר',
    primary: '#FF5252',
    secondary: '#FF8A80',
  },
  {
    id: 12,
    name: 'אדום זוהר',
    primary: '#FF1744',
    secondary: '#FF616F',
  },

  // ורודים וסגולים
  {
    id: 13,
    name: 'ורוד',
    primary: '#FF4081',
    secondary: '#FF80AB',
  },
  {
    id: 14,
    name: 'פוקסיה',
    primary: '#F50057',
    secondary: '#FF4081',
  },
  {
    id: 15,
    name: 'סגול בהיר',
    primary: '#7C4DFF',
    secondary: '#B388FF',
  },
  {
    id: 18,
    name: 'סגול',
    primary: '#9C27B0', // סגול בהיר ומודגש יותר
    secondary: '#CE93D8',
  },
  {
    id: 16,
    name: 'אפור פנינה',
    primary: '#78909C',
    secondary: '#B0BEC5',
  },
  {
    id: 21,
    name: 'כחול אפור',
    primary: '#546E7A', // כחול אפור לניגודיות גבוהה
    secondary: '#90A4AE',
  },
];
