import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  fetchManagerMenuItems,
  addMenuItem,
  editMenuItem,
  removeMenuItem,
} from './managerMenuThunks';
import {
  deleteFilterThunk,
  addFilter,
  updateFilterThunk,
} from '../filter/filterThunk';
import {
  updateCategoryThunk,
  deleteCategoryThunk,
} from '../category/CategoryThunk';

const managerMenuSlice = createSlice({
  name: 'managerMenu',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    changesPending: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchManagerMenuItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchManagerMenuItems.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchManagerMenuItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Add Menu Item
      .addCase(addMenuItem.pending, (state) => {
        state.changesPending = true;
      })
      .addCase(addMenuItem.fulfilled, (state, action) => {
        state.changesPending = false;
        state.items.push(action.payload);
      })
      .addCase(addMenuItem.rejected, (state, action) => {
        state.changesPending = false;
        state.error = action.error.message;
      })

      // Edit Menu Item
      .addCase(editMenuItem.pending, (state) => {
        state.changesPending = true;
      })
      .addCase(editMenuItem.fulfilled, (state, action) => {
        state.changesPending = false;
        const index = state.items.findIndex(
          (item) => item._id === action.payload._id
        );
        if (index !== -1) {
          state.items[index] = {
            ...state.items[index],
            ...action.payload,
          };
        }
      })
      .addCase(editMenuItem.rejected, (state, action) => {
        state.changesPending = false;
        state.error = action.error.message;
      })

      // Remove Menu Item
      .addCase(removeMenuItem.pending, (state) => {
        state.changesPending = true;
      })
      .addCase(removeMenuItem.fulfilled, (state, action) => {
        state.changesPending = false;
        state.items = state.items.filter((item) => item._id !== action.payload);
      })
      .addCase(removeMenuItem.rejected, (state, action) => {
        state.changesPending = false;
        state.error = action.error.message;
      })

      // Add Filter
      .addCase(addFilter.fulfilled, (state, action) => {
        const newFilter = action.payload;
        state.items = state.items.map((item) => {
          if (item._id === newFilter.menuItemId) {
            return {
              ...item,
              filters: [...item.filters, newFilter],
            };
          }
          return item;
        });
      })

      // Delete Filter
      .addCase(deleteFilterThunk.fulfilled, (state, action) => {
        state.items = state.items.map((item) => ({
          ...item,
          filters: item.filters.filter(
            (filter) => filter._id !== action.payload
          ),
        }));
      })

      // Update Filter
      .addCase(updateFilterThunk.fulfilled, (state, action) => {
        state.items = state.items.map((item) => ({
          ...item,
          filters: item.filters.map((filter) =>
            filter._id === action.payload._id ? action.payload : filter
          ),
        }));
      })

      // Update Category
      .addCase(updateCategoryThunk.fulfilled, (state, action) => {
        state.items = state.items.map((item) =>
          item.categoryId === action.payload._id
            ? { ...item, categoryId: action.payload }
            : item
        );
      })

      // Delete Category
      .addCase(deleteCategoryThunk.fulfilled, (state, action) => {
        state.items = state.items.filter(
          (item) => item.categoryId !== action.payload
        );
      });
  },
});

// Basic selectors
const selectManagerMenuState = (state) => state.managerMenu;

// Memoized selectors
export const selectManagerMenu = createSelector(
  [selectManagerMenuState],
  (managerMenuState) => managerMenuState.items
);

export const selectManagerStatus = createSelector(
  [selectManagerMenuState],
  (managerMenuState) => managerMenuState.status
);

export const selectMenu = (state) => state.managerMenu.items;
export const selectStatus = (state) => state.managerMenu.status;

export default managerMenuSlice.reducer;
