import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  MenuItem,
  Modal,
  Paper,
  Tooltip,
  RadioGroup,
  Container,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateConfigurations,
  selectConfiguration,
} from '../../features/configuration/configurationSlice';
import ImageCropper from './ImageCropper';
import { LoadingButton } from '@mui/lab';
import { COLOR_COMBINATIONS } from '../../styles/themeColors';

const SaveButton = ({ hasChanges, isSaving }) => (
  <LoadingButton
    type="submit"
    variant="contained"
    color="primary"
    disabled={!hasChanges}
    loading={isSaving}
    loadingPosition="start"
    startIcon={<SaveIcon />}
    sx={{ width: '12rem', height: '4rem', fontSize: '1.1rem' }}
  >
    שמור שינויים
  </LoadingButton>
);

const ThemeConfigPage = ({ toggleTheme }) => {
  const dispatch = useDispatch();
  const configuration = useSelector(selectConfiguration);
  const initialConfig = useMemo(() => {
    return (
      configuration[0] || {
        primaryColor: '#00796B',
        secondaryColor: '#a5d6a7',
        fontFamily: "'Assistant', sans-serif",
        mainImage: '',
        darkMode: 0, // default darkMode value
      }
    );
  }, [configuration]);
  const [localConfig, setLocalConfig] = useState(initialConfig);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [selectedColorSet, setSelectedColorSet] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (configuration.length > 0) {
      setLocalConfig(configuration[0]);
      const matchingSet = COLOR_COMBINATIONS.find(
        (set) =>
          set.primary === configuration[0].primaryColor &&
          set.secondary === configuration[0].secondaryColor
      );
      setSelectedColorSet(matchingSet?.id || null);
    }
  }, [configuration]);

  // Check for changes whenever localConfig is updated
  useEffect(() => {
    const hasConfigurationChanges =
      JSON.stringify(localConfig) !== JSON.stringify(initialConfig);
    const hasImageChanges = imageFile !== null;
    setHasChanges(hasConfigurationChanges || hasImageChanges);
  }, [localConfig, imageFile, initialConfig]);

  const handleColorSetChange = (event) => {
    const setId = Number(event.target.value);
    const colorSet = COLOR_COMBINATIONS.find((set) => set.id === setId);
    setSelectedColorSet(setId);

    if (colorSet) {
      setLocalConfig((prev) => ({
        ...prev,
        primaryColor: colorSet.primary,
        secondaryColor: colorSet.secondary,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  const handleImageSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 10 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        alert('Only JPEG and PNG files are allowed.');
        return;
      }

      if (file.size > maxSize) {
        alert('File size must be less than 10MB.');
        return;
      }

      setSelectedImage(URL.createObjectURL(file));
      setCropperOpen(true);
    }
  };

  const handleCropComplete = async (croppedBlob) => {
    setCropperOpen(false);
    setSelectedImage(null);
    setImageFile(croppedBlob);
    const previewUrl = URL.createObjectURL(croppedBlob);
    setLocalConfig((prev) => ({
      ...prev,
      mainImage: previewUrl,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (localConfig && localConfig._id) {
      setIsSaving(true);
      const formData = new FormData();

      Object.entries(localConfig).forEach(([key, value]) => {
        if (key !== 'mainImage') {
          formData.append(key, value);
        }
      });

      if (imageFile) {
        formData.append('mainImage', imageFile);
      }

      try {
        await dispatch(
          updateConfigurations({
            id: localConfig._id,
            configuration: formData,
          })
        );
        setImageFile(null);
        setHasChanges(false);
      } catch (error) {
        console.error('Failed to update configuration:', error);
        alert('שגיאה בעדכון התצורה');
      } finally {
        setIsSaving(false);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 3,
        }}
      >
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" align="right" sx={{ mb: 2 }}>
              ערכות צבעים
            </Typography>
            <RadioGroup
              value={selectedColorSet || ''}
              onChange={handleColorSetChange}
              sx={{ width: '100%' }}
            >
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                justifyContent="space-between"
                style={{ height: '100%', direction: 'rtl' }}
              >
                {COLOR_COMBINATIONS.map((combination) => (
                  <Grid
                    key={combination.id}
                    size={{ xs: 6, sm: 3, md: 2.4 }}
                    style={{
                      paddingRight: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Paper
                      elevation={selectedColorSet === combination.id ? 8 : 1}
                      sx={{
                        p: 1.5,
                        cursor: 'pointer',
                        border: selectedColorSet === combination.id ? 2 : 1,
                        borderColor:
                          selectedColorSet === combination.id
                            ? combination.primary
                            : 'grey.300',
                        '&:hover': {
                          elevation: 4,
                          transform: 'translateY(-2px)',
                          transition: 'transform 0.2s ease-in-out',
                        },
                        position: 'relative',
                        transition: 'all 0.2s ease-in-out',
                        backgroundColor:
                          selectedColorSet === combination.id
                            ? `${combination.secondary}15`
                            : 'background.paper',
                      }}
                      onClick={() =>
                        handleColorSetChange({
                          target: { value: combination.id },
                        })
                      }
                    >
                      <Box>
                        <Typography
                          variant="subtitle2"
                          align="right"
                          sx={{
                            mb: 1,
                            color:
                              selectedColorSet === combination.id
                                ? combination.primary
                                : 'text.primary',
                            fontWeight:
                              selectedColorSet === combination.id ? 600 : 400,
                          }}
                        >
                          {combination.name}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1.5,
                            justifyContent: 'flex',
                          }}
                        >
                          <Tooltip title="צבע משני" placement="top">
                            <Box
                              sx={{
                                width: 28,
                                height: 28,
                                borderRadius: '50%',
                                bgcolor: combination.secondary,
                                border: '1px solid rgba(0,0,0,0.1)',
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  transform: 'scale(1.1)',
                                },
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="צבע ראשי" placement="top">
                            <Box
                              sx={{
                                width: 28,
                                height: 28,
                                borderRadius: '50%',
                                bgcolor: combination.primary,
                                border: '1px solid rgba(0,0,0,0.1)',
                                transition: 'transform 0.2s ease-in-out',
                                '&:hover': {
                                  transform: 'scale(1.1)',
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Box>
                      <input
                        type="radio"
                        name="colorSet"
                        value={combination.id}
                        checked={selectedColorSet === combination.id}
                        onChange={() => {}}
                        style={{
                          position: 'absolute',
                          opacity: 0,
                          width: 0,
                          height: 0,
                        }}
                      />
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Box>

          <Box
            sx={{
              mb: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: 2,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <Typography align="right" sx={{ mb: 1 }}>
                משפחת גופנים
              </Typography>
              <TextField
                select
                size="small"
                name="fontFamily"
                value={localConfig.fontFamily}
                onChange={handleChange}
                sx={{
                  width: '15rem',
                  '& .MuiSelect-select': {
                    textAlign: 'right',
                  },
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  PaperProps: {
                    sx: {
                      '& .MuiMenu-list': {
                        textAlign: 'right',
                      },
                    },
                  },
                }}
              >
                <MenuItem value="'Assistant', sans-serif">Assistant</MenuItem>
                <MenuItem value="'Rubik', sans-serif">Rubik</MenuItem>
                <MenuItem value="'Heebo', sans-serif">Heebo</MenuItem>
                <MenuItem value="'Open Sans Hebrew', sans-serif">
                  Open Sans Hebrew
                </MenuItem>
                <MenuItem value="'Noto Sans Hebrew', sans-serif">
                  Noto Sans Hebrew
                </MenuItem>
                <MenuItem value="'Varela Round', sans-serif">
                  Varela Round
                </MenuItem>
                <MenuItem value="'David Libre', serif">David Libre</MenuItem>
                <MenuItem value="'Frank Ruhl Libre', serif">
                  Frank Ruhl Libre
                </MenuItem>
              </TextField>

              <Typography align="right" sx={{ mt: 3, mb: 1 }}>
                מצב כהה
              </Typography>
              <TextField
                select
                size="small"
                name="darkMode"
                value={localConfig.darkMode}
                onChange={handleChange}
                sx={{ width: '15rem', textAlign: 'right' }}
              >
                <MenuItem value={0} sx={{ justifyContent: 'flex-end' }}>
                  ללא מצב כהה
                </MenuItem>
                <MenuItem value={1} sx={{ justifyContent: 'flex-end' }}>
                  מצב כהה מופעל
                </MenuItem>
                <MenuItem value={2} sx={{ justifyContent: 'flex-end' }}>
                  משתנה בהתאם לשעה ביום
                </MenuItem>
                <MenuItem value={3} sx={{ justifyContent: 'flex-end' }}>
                  בהתאם להגדרות המכשיר
                </MenuItem>
              </TextField>
            </Box>

            <Box sx={{ width: '100%' }}>
              <Typography align="right" sx={{ mb: 1 }}>
                תמונה ראשית
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    width: 'fit-content',
                    alignSelf: 'flex-end',
                  }}
                >
                  בחר תמונה
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageSelect}
                  />
                </Button>

                {localConfig.mainImage && (
                  <Box
                    component="img"
                    src={localConfig.mainImage}
                    alt="Main Image"
                    sx={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                      borderRadius: 1,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 4,
            }}
          >
            <SaveButton hasChanges={hasChanges} isSaving={isSaving} />
          </Box>
        </form>
      </Box>

      <Modal
        open={cropperOpen}
        onClose={() => {
          setCropperOpen(false);
          setSelectedImage(null);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            p: 4,
          }}
        >
          {selectedImage && (
            <ImageCropper
              src={selectedImage}
              onCrop={handleCropComplete}
              aspectRatio={13 / 3}
              showCircleOverlay={false}
            />
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default ThemeConfigPage;
