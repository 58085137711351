import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/filters';
const local_URL = 'http://localhost:3001/api/filters';
/* eslint-enable no-unused-vars */
const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Fetch all filters
export const getFilters = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

// Fetch a single filter by ID
export const getFilter = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new filter
export const createFilter = async (filter) => {
  const response = await api.post(BASE_URL, filter);
  return response.data;
};

// Update a filter
export const updateFilter = async (id, filter) => {
  const response = await api.put(BASE_URL + `/${id}`, filter);
  return response.data;
};

// Delete a filter
export const deleteFilter = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};
