import axios from 'axios';
import { DateTime } from 'luxon';

export const getSunTimes = async (latitude, longitude) => {
  try {
    const response = await axios.get('https://api.sunrise-sunset.org/json', {
      params: {
        lat: latitude,
        lng: longitude,
        formatted: 0, // Use ISO 8601 format
      },
    });

    const sunriseTimeUTC = DateTime.fromISO(response.data.results.sunrise);
    const sunsetTimeUTC = DateTime.fromISO(response.data.results.sunset);

    const sunriseTimeIsrael = sunriseTimeUTC.setZone('Asia/Jerusalem');
    const sunsetTimeIsrael = sunsetTimeUTC.setZone('Asia/Jerusalem');

    return {
      sunRiseTime: sunriseTimeIsrael.toFormat('HH:mm'),
      sunSetTime: sunsetTimeIsrael.toFormat('HH:mm')
    };
  } catch (error) {
    console.error('Error fetching sun times:', error);
    return null;
  }
};