import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/working-hours';
const local_URL = 'http://localhost:3001/api/working-hours';
/* eslint-enable no-unused-vars */

const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Fetch all working hours
export const getWorkingHours = async () => {
  const response = await api.get(BASE_URL);
  return response.data;
};

// Fetch a single working hour by ID
export const getWorkingHour = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new working hour
export const createWorkingHour = async (workingHour) => {
  const response = await api.post(BASE_URL, workingHour);
  return response.data;
};

// Update a working hour
export const updateWorkingHour = async (id, workingHour) => {
  const response = await api.put(BASE_URL + `/${id}`, workingHour);
  return response.data;
};

// Delete a working hour
export const deleteWorkingHour = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};
