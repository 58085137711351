import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  Paper,
  Divider,
  Stack,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
} from '@mui/material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';

const FormViewer = () => {
  const { formId } = useParams();
  const navigate = useNavigate();

  // State management
  const [form, setForm] = useState(null);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Common text field styles
  const textFieldStyles = {
    '& .MuiInputBase-input': {
      textAlign: 'right',
      direction: 'rtl',
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
      marginRight: '14px',
      marginLeft: 0,
    },
    '& .MuiInputLabel-root': {
      right: 0,
      left: 'auto',
      transformOrigin: 'right',
    },
  };

  // Validation patterns and messages
  const validationRules = {
    email: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'נא להזין כתובת אימייל תקינה',
    },
    text: {
      minLength: 2,
      message: 'נא להזין לפחות 2 תווים',
    },
    multiple: {
      minSelections: 1,
      message: 'נא לבחור לפחות אפשרות אחת',
    },
    radio: {
      message: 'נא לבחור אפשרות',
    },
  };

  // Validate single field
  const validateField = (field, value) => {
    if (field.required) {
      if (!value || (typeof value === 'string' && value.trim() === '')) {
        return 'שדה חובה';
      }

      switch (field.type) {
        case 'email':
          if (!validationRules.email.pattern.test(value)) {
            return validationRules.email.message;
          }
          break;

        case 'text':
          if (value.length < validationRules.text.minLength) {
            return validationRules.text.message;
          }
          break;

        case 'multiple':
          if (
            Array.isArray(value) &&
            value.length < validationRules.multiple.minSelections
          ) {
            return validationRules.multiple.message;
          }
          break;

        case 'radio':
          if (!value) {
            return validationRules.radio.message;
          }
          break;

        default:
          break;
      }
    }
    return '';
  };

  // Show snackbar notification
  const showSnackbar = useCallback((message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  // Load form from localStorage
  const loadForm = useCallback(() => {
    try {
      const savedForms = JSON.parse(
        localStorage.getItem('employee_forms') || '[]'
      );
      const foundForm = savedForms.find((f) => f.id.toString() === formId);
      if (foundForm) {
        setForm(foundForm);
      } else {
        showSnackbar('הטופס לא נמצא', 'error');
        navigate('/forms');
      }
    } catch (error) {
      showSnackbar('שגיאה בטעינת הטופס', 'error');
      navigate('/forms');
    }
  }, [formId, navigate, showSnackbar]);

  // Load form data on mount
  useEffect(() => {
    loadForm();
  }, [loadForm]);

  // Initialize answers when form loads
  useEffect(() => {
    if (form) {
      const initialAnswers = {};
      form.fields.forEach((field) => {
        if (field.type === 'multiple') {
          initialAnswers[field.id] = [];
        } else {
          initialAnswers[field.id] = '';
        }
      });
      setAnswers(initialAnswers);
    }
  }, [form]);

  // Handle answer changes
  const handleAnswerChange = (fieldId, value, isMultiple = false) => {
    const field = form.fields.find((f) => f.id === fieldId);
    let newValue;

    if (isMultiple) {
      newValue = answers[fieldId].includes(value)
        ? answers[fieldId].filter((v) => v !== value)
        : [...answers[fieldId], value];
    } else {
      newValue = value;
    }

    setAnswers((prev) => ({
      ...prev,
      [fieldId]: newValue,
    }));

    // Validate field on change
    const error = validateField(field, newValue);
    setErrors((prev) => ({
      ...prev,
      [fieldId]: error,
    }));
  };

  // Validate entire form
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    form.fields.forEach((field) => {
      const error = validateField(field, answers[field.id]);
      if (error) {
        newErrors[field.id] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      showSnackbar('נא לתקן את השגיאות בטופס', 'error');
    }

    return isValid;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const submission = {
        id: Date.now(),
        formId: form.id,
        formTitle: form.title,
        submittedAt: new Date().toISOString(),
        answers: answers,
      };

      // Save submission to localStorage
      const submissions = JSON.parse(
        localStorage.getItem('form_submissions') || '[]'
      );
      submissions.push(submission);
      localStorage.setItem('form_submissions', JSON.stringify(submissions));

      showSnackbar('הטופס נשלח בהצלחה!');

      setTimeout(() => {
        navigate('/forms');
      }, 2000);
    } catch (error) {
      showSnackbar('שגיאה בשליחת הטופס', 'error');
    } finally {
      setIsSubmitting(false);
      setConfirmDialogOpen(false);
    }
  };

  if (!form) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography>טוען טופס...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          endIcon={<ArrowForwardIcon />}
          onClick={() => navigate('/forms')}
        >
          חזרה לטפסים
        </Button>
      </Box>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          component="h1"
          sx={{ textAlign: 'right' }}
        >
          {form.title}
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Stack spacing={3}>
          {form.fields.map((field, index) => (
            <Box key={field.id} sx={{ textAlign: 'right' }}>
              <Typography
                variant="h6"
                gutterBottom
                color={field.required ? 'error.main' : 'inherit'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                {field.required && (
                  <ErrorIcon
                    sx={{ ml: 1, fontSize: 20, color: 'error.main' }}
                  />
                )}
                {field.label} .{index + 1}
              </Typography>

              {/* Text Field */}
              {field.type === 'text' && (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={answers[field.id] || ''}
                  onChange={(e) => handleAnswerChange(field.id, e.target.value)}
                  placeholder="הזן את תשובתך"
                  disabled={field.isFixed || false}
                  required={field.required}
                  error={!!errors[field.id]}
                  helperText={errors[field.id]}
                  sx={textFieldStyles}
                />
              )}

              {/* Email Field */}
              {field.type === 'email' && (
                <TextField
                  fullWidth
                  type="email"
                  value={answers[field.id] || ''}
                  onChange={(e) => handleAnswerChange(field.id, e.target.value)}
                  placeholder="הזן כתובת אימייל"
                  required={field.required}
                  error={!!errors[field.id]}
                  helperText={errors[field.id]}
                  sx={textFieldStyles}
                />
              )}

              {/* Radio Buttons */}
              {field.type === 'radio' && (
                <>
                  <RadioGroup
                    value={answers[field.id] || ''}
                    onChange={(e) =>
                      handleAnswerChange(field.id, e.target.value)
                    }
                  >
                    {field.options.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        value={option}
                        control={<Radio />}
                        label={option}
                        sx={{
                          margin: 0,
                          flexDirection: 'row-reverse',
                          '& .MuiFormControlLabel-label': { marginRight: 1 },
                        }}
                      />
                    ))}
                  </RadioGroup>
                  {errors[field.id] && (
                    <FormHelperText error sx={{ textAlign: 'right' }}>
                      {errors[field.id]}
                    </FormHelperText>
                  )}
                </>
              )}

              {/* Multiple Choice */}
              {field.type === 'multiple' && (
                <>
                  <FormGroup>
                    {field.options.map((option, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={
                              answers[field.id]?.includes(option) || false
                            }
                            onChange={() =>
                              handleAnswerChange(field.id, option, true)
                            }
                          />
                        }
                        label={option}
                        sx={{
                          margin: 0,
                          flexDirection: 'row-reverse',
                          '& .MuiFormControlLabel-label': { marginRight: 1 },
                        }}
                      />
                    ))}
                  </FormGroup>
                  {errors[field.id] && (
                    <FormHelperText error sx={{ textAlign: 'right' }}>
                      {errors[field.id]}
                    </FormHelperText>
                  )}
                </>
              )}
            </Box>
          ))}
        </Stack>

        <Box
          sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'flex-start' }}
        >
          <Button
            variant="contained"
            onClick={() => setConfirmDialogOpen(true)}
            disabled={isSubmitting}
          >
            שליחה
          </Button>
          <Button variant="outlined" onClick={() => navigate('/forms')}>
            ביטול
          </Button>
        </Box>
      </Paper>

      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            textAlign: 'right',
          },
        }}
      >
        <DialogTitle>אישור שליחה</DialogTitle>
        <DialogContent>
          <Typography>
            האם אתה בטוח שברצונך לשלוח את הטופס? אנא בדוק את תשובותיך לפני
            השליחה.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            שליחה
          </Button>
          <Button onClick={() => setConfirmDialogOpen(false)}>סקירה</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FormViewer;
