import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MenuPage from './pages/MenuPage';
import ManagerDashboard from './pages/ManagerMenuDashboard';
import ThemeConfigPage from './components/dashboardComponents/ThemeConfigPage';
import ConfigLayout from './layouts/ConfigLayout';
import FormViewer from './components/EmployeeFormComponenents/FormViewer';
import WorkingHoursManager from './components/dashboardComponents/WorkingHoursManager';
import ConfigurationManager from './components/dashboardComponents/ConfigurationManager';
import { ProtectedRoute } from './auth';

const AppRoutes = ({ toggleTheme, handleLogin }) => {
  const useProtectedRoutes = false; // Set to true to enable ProtectedRoute

  return (
    <Routes>
      {/* Public Routes */}
      <Route path='/' element={<Navigate to='/menu' />} />
      <Route path='/menu' element={<MenuPage toggleTheme={toggleTheme} />} />

      {/* Conditionally Protected Routes */}
      <Route
        path='/dashboard'
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <ManagerDashboard toggleTheme={toggleTheme} />
            </ProtectedRoute>
          ) : (
            <ManagerDashboard toggleTheme={toggleTheme} />
          )
        }
      />

      {/* Conditionally Protected Config Routes */}
      <Route
        path='/config'
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <ConfigLayout />
            </ProtectedRoute>
          ) : (
            <ConfigLayout />
          )
        }
      >
        <Route index element={<Navigate to='configurations' replace />} />
        <Route path='theme' element={<ThemeConfigPage />} />
        <Route path='hours' element={<WorkingHoursManager />} />
        <Route path='configurations' element={<ConfigurationManager />} />
      </Route>

      {/* Conditionally Protected Form Routes */}
      <Route
        path='/forms/fill/:formId'
        element={
          useProtectedRoutes ? (
            <ProtectedRoute>
              <FormViewer />
            </ProtectedRoute>
          ) : (
            <FormViewer />
          )
        }
      />

      {/* Legacy Route Redirects */}
      <Route
        path='/theme-config'
        element={<Navigate to='/config/theme' replace />}
      />
      <Route path='/forms' element={<Navigate to='/config/forms' replace />} />
      <Route
        path='/forms/submissions'
        element={<Navigate to='/config/submissions' replace />}
      />
    </Routes>
  );
};

export default AppRoutes;
