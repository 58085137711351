import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfiguration,
  updateConfigurations,
} from '../../features/configuration/configurationSlice';
import {
  Box,
  TextField,
  Button,
  Stack,
  Typography,
  Container,
  Switch,
  FormControlLabel,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const FIELD_LABELS = [
  'שם העסק',
  'כתובת העסק',
  'טלפון',
  'אימייל',
  'קישור לעמוד פייסבוק',
  'קישור לעמוד אינסטגרם',
  'המקום פתוח',
  'ניהול מלאי',
];

const SaveButton = ({ onClick, disabled }) => (
  <Button
    variant="contained"
    endIcon={<SaveIcon />}
    onClick={onClick}
    disabled={disabled}
    sx={{
      minWidth: 120,
      '& .MuiButton-endIcon': {
        marginRight: 1,
        marginLeft: -1,
      },
    }}
  >
    שמור שינויים
  </Button>
);

const ConfigurationManager = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(selectConfiguration);
  const [editedValues, setEditedValues] = useState({});

  const handleEditChange = (id, field, value) => {
    setEditedValues((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: typeof value === 'boolean' ? value : value, // Only applies boolean check for boolean types
      },
    }));
  };

  const handleSaveAll = async () => {
    try {
      const updatePromises = configuration.map(async (config) => {
        if (editedValues[config._id]) {
          const updatedConfig = {
            ...config,
            ...editedValues[config._id], // Merges edited values into the existing configuration
          };
  
          // Dispatch the updated configuration object directly
          await dispatch(
            updateConfigurations({
              id: config._id,
              configuration: updatedConfig,
            })
          ).unwrap();
  
          return config;
        }
        return config;
      });
  
      await Promise.all(updatePromises);
      setEditedValues({});
    } catch (error) {
      console.error('Failed to save configurations:', error);
    }
  };
  

  const renderField = (config, key, label) => {
    const currentValue = editedValues[config._id]?.[key] ?? config[key];
    const isBoolean = typeof currentValue === 'boolean';

    if (isBoolean) {
      return (
        <Box sx={{ mb: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              mb: 1,
              fontWeight: 'bold',
              fontSize: '1.1rem',
              color: 'text.primary',
            }}
          >
            {label}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              py: 1,
              bgcolor: 'background.default',
              borderRadius: 1,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(currentValue)}
                  onChange={(e) =>
                    handleEditChange(config._id, key, e.target.checked)
                  }
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: 'primary.main',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: 'primary.main',
                    },
                  }}
                />
              }
              label={currentValue ? 'פעיל' : 'לא פעיל'}
              sx={{
                margin: 0,

                '& .MuiFormControlLabel-label': {
                  color: 'text.secondary',
                },
              }}
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 1,
            fontWeight: 'bold',
            fontSize: '1.1rem',
            color: 'text.primary',
          }}
        >
          {label}
        </Typography>
        <TextField
          fullWidth
          value={currentValue || ''}
          onChange={(e) => handleEditChange(config._id, key, e.target.value)}
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              direction: 'rtl',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              textAlign: 'right',
            },
          }}
          InputProps={{
            sx: {
              direction: 'rtl',
              textAlign: 'right',
            },
          }}
        />
      </Box>
    );
  };

  const hasChanges = Object.keys(editedValues).length > 0;

  return (
    <Container maxWidth="md" dir="rtl" sx={{ padding: '0' }}>
      <Box elevation={3} sx={{ mt: 4, mb: 4 }}>
        <Stack sx={{ direction: 'rtl' }}>
          {configuration.map((config) => (
            <Box key={config._id}>
              {Object.keys(config)
                .filter(
                  (key) =>
                    ![
                      '_id',
                      'createdAt',
                      'updatedAt',
                      'primaryColor',
                      'secondaryColor',
                      'font',
                      'fontFamily',
                      'mainImage',
                      'darkMode',
                    ].includes(key)
                )
                .map((key, index) => {
                  const label = FIELD_LABELS[index];
                  return (
                    <Box key={`${config._id}-${key}`}>
                      {renderField(config, key, label)}
                    </Box>
                  );
                })}
            </Box>
          ))}
        </Stack>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            mt: 4,
            direction: 'rtl',
          }}
        >
          <SaveButton onClick={handleSaveAll} disabled={!hasChanges} />
        </Box>
      </Box>
    </Container>
  );
};

export default ConfigurationManager;
