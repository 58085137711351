import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConfigurations, updateConfiguration } from "../../api/configurationApi";

export const fetchConfiguration = createAsyncThunk(
    "configuration/fetchConfiguration",
    async () => {
        const response = await getConfigurations();
        return response;
    }
    );

export const updateConfigurations = createAsyncThunk(
    "configuration/updateConfigurations",
    async ({ id, configuration }, { rejectWithValue }) => {
        try {
            const response = await updateConfiguration(id, configuration);
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
    );


