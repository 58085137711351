import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ImageCropper from './ImageCropper';
import CategoryDropdown from './CategoryDropdown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditableField from '../core/editableField';
import AddIcon from '@mui/icons-material/Add';
import { DragIndicator } from '@mui/icons-material';
import AddFilterPopup from './addFilterPopup';
import FilterOrderPopup from './FilterOrderPopup';
import EditIcon from '@mui/icons-material/Edit';
import ImagePlaceholder from '../../assets/ImagePlaceholder.png';
import { useSelector } from 'react-redux';
import { selectConfiguration } from '../../features/configuration/configurationSlice';

const MenuItemPopup = ({ open, onClose, item, onSave, categories }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [editedItem, setEditedItem] = useState({
    _id: '',
    name: '',
    description: '',
    image: null,
    filters: [],
    ...item,
    price: Number(item.price) || 0,
    categoryId: item.categoryId ? item.categoryId._id : '',
    isActive: item.isActive,
    quantityInStock: item.quantityInStock || 0,
    isAvailable: item.isAvailable,
  });
  const [isCropperOpen, setIsCropperOpen] = useState(false); // State to manage cropper visibility
  const [croppedImage, setCroppedImage] = useState(null); // State to store cropped image
  const [imageFile, setImageFile] = useState(null); // State to store the image file
  const fileInputRef = useRef(null);
  const [modifiedFilters, setModifiedFilters] = useState({});
  const [isAddFilterDialogOpen, setIsAddFilterDialogOpen] = useState(false);
  const [isFilterOrderPopupOpen, setIsFilterOrderPopupOpen] = useState(false); // New state for FilterOrderPopup
  const [errors, setErrors] = useState({});
  const [editingFilter, setEditingFilter] = useState(null);
  const configurations = useSelector(selectConfiguration);
  const manageStock = configurations?.[0]?.manageStock || false;

  useEffect(() => {
    setEditedItem({
      _id: '',
      name: '',
      description: '',
      image: null,
      filters: [],
      ...item,
      price: Number(item.price) || 0,
      categoryId: item.categoryId ? item.categoryId._id : '',
      isActive: item.isActive,
      quantityInStock: item.quantityInStock || 0,
      isAvailable: item.isAvailable,
    });
  }, [item]);

  const validateForm = () => {
    const newErrors = {};

    if (!editedItem.name.trim()) {
      newErrors.name = 'שדה חובה';
    }
    if (!editedItem.price || editedItem.price <= 0) {
      newErrors.price = 'נדרש מחיר חוקי';
    }
    if (!editedItem.categoryId) {
      newErrors.categoryId = 'יש לבחור קטגוריה';
    }

    editedItem.filters.forEach((filter, filterIndex) => {
      if (!filter.name.trim()) {
        newErrors[`filter_${filter._id}_name`] = 'שדה חובה';
      }

      filter.options.forEach(([optionName, optionPrice], optionIndex) => {
        if (!optionName.trim()) {
          if (!newErrors[filter._id]) {
            newErrors[filter._id] = [];
          }
          if (!newErrors[filter._id][optionIndex]) {
            newErrors[filter._id][optionIndex] = {};
          }
          newErrors[filter._id][optionIndex].name = 'שדה חובה';
        }
        if (!optionPrice.trim()) {
          if (!newErrors[filter._id]) {
            newErrors[filter._id] = [];
          }
          if (!newErrors[filter._id][optionIndex]) {
            newErrors[filter._id][optionIndex] = {};
          }
          newErrors[filter._id][optionIndex].price = 'שדה חובה';
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /* useEffect(() => {
    console.log('Errors state updated:', errors);
  }, [errors]); */
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Define allowed file types and maximum file size (e.g., 10MB)
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        alert('Only JPEG, PNG are allowed.');
        return;
      }

      // Check if the file size is within the limit
      if (file.size > maxSize) {
        alert('File size must be less than 10MB.');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setCroppedImage(reader.result);
        setIsCropperOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCrop = (croppedBlob) => {
    const url = URL.createObjectURL(croppedBlob);
    setCroppedImage(url); // Set the URL of the cropped image for display
    setImageFile(croppedBlob); // Store the cropped Blob
    setEditedItem({ ...editedItem, image: url }); // Update the edited item with the image URL
    setIsCropperOpen(false);
  };

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (field, value) => {
    const updatedItem = {
      ...editedItem,
      [field]: field === 'price' ? Number(value) : value,
    };
    if (field === 'categoryId') {
      updatedItem.categoryId = value;
    }
    setEditedItem(updatedItem);

    // Only clear error if the new value is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (field === 'name' && updatedItem.name.trim()) {
        newErrors.name = '';
      } else if (
        field === 'price' &&
        (updatedItem.price || updatedItem.price >= 0)
      ) {
        newErrors.price = '';
      } else if (field === 'categoryId' && updatedItem.categoryId) {
        delete newErrors[field];
      }
      return newErrors;
    });
  };

  const handleUpdateFiltersOrder = (updatedFilters) => {
    // Update the local `modifiedFilters` state with the updated filters
    setModifiedFilters((prev) => ({
      ...prev,
      ...updatedFilters, // Merge updated filters into modifiedFilters
    }));

    // Also update the `editedItem.filters` to reflect the changes in the UI immediately
    setEditedItem((prevItem) => ({
      ...prevItem,
      filters: prevItem.filters.map((filter) =>
        updatedFilters[filter._id] ? updatedFilters[filter._id] : filter
      ),
    }));

    // No API calls to update the filters are made here, as they should only be sent to the server on "Save Changes"
  };

  const handleEditFilter = (filter) => {
    setEditingFilter(filter);
    setIsAddFilterDialogOpen(true);
  };

  const handleUpdateFilter = (updatedFilter) => {
    const updatedFilters = editedItem.filters.map((filter) =>
      filter._id === updatedFilter._id ? updatedFilter : filter
    );
    setEditedItem({ ...editedItem, filters: updatedFilters });
    // Update the modifiedFilters state
    setModifiedFilters((prevModifiedFilters) => ({
      ...prevModifiedFilters,
      [updatedFilter._id]: updatedFilter,
    }));
    setIsAddFilterDialogOpen(false);
    setEditingFilter(null);
  };

  const handleAddFilter = (newFilter) => {
    const tempId = `temp_${Date.now()}`;
    const newFilterWithId = { ...newFilter, _id: tempId };

    // Immediately reflect the new filter in the popup's filters list
    setEditedItem((prevItem) => ({
      ...prevItem,
      filters: [
        ...prevItem.filters,
        newFilterWithId, // Temporary _id until saved in the database
      ],
    }));

    // Add the new filter to the modifiedFilters state
    setModifiedFilters((prevFilters) => ({
      ...prevFilters,
      [tempId]: newFilterWithId,
    }));

    setIsAddFilterDialogOpen(false); // Close the "Add Filter" dialog
  };

  const handleOnSaveFilter = (savedFilter, isExistingFilter) => {
    if (isExistingFilter) {
      handleUpdateFilter(savedFilter);
    } else {
      handleAddFilter(savedFilter);
    }
  };

  const handleDeleteFilter = (filterId) => {
    if (filterId.startsWith('temp_')) {
      // Remove the new filter from the local state
      setEditedItem((prevItem) => ({
        ...prevItem,
        filters: prevItem.filters.filter((filter) => filter._id !== filterId),
      }));

      setModifiedFilters((prev) => {
        const { [filterId]: _, ...remainingFilters } = prev;
        return remainingFilters;
      });
    } else {
      // Mark the filter as deleted locally
      setEditedItem((prevItem) => ({
        ...prevItem,
        filters: prevItem.filters.filter((filter) => filter._id !== filterId),
      }));

      // If the filter already exists in the database, track it in `modifiedFilters` for deletion later
      setModifiedFilters((prev) => ({
        ...prev,
        [filterId]: { ...prev[filterId], _deleted: true }, // Mark the filter for deletion
      }));
    }
  };

  const handleEditOrderClick = () => {
    setIsFilterOrderPopupOpen(true);
  };

  const handleFilterOrderClose = () => {
    setIsFilterOrderPopupOpen(false);
  };

  // handleToggle function to handle the switch for isActive
  const handleToggle = (event) => {
    const newIsActive = event.target.checked;
    setEditedItem((prevItem) => ({
      ...prevItem,
      isActive: newIsActive,
    }));
  };

  // handleAvailableToggle function to handle the switch for isAvailable
  const handleAvailableToggle = (event) => {
    const newIsAvailable = event.target.checked;
    setEditedItem((prevItem) => ({
      ...prevItem,
      isAvailable: newIsAvailable,
    }));
  };

  const handleSave = () => {
    if (validateForm()) {
      // Prepare data for saving
      console.log('Form is valid, proceed with save');
      const formData = new FormData();

      // Add main fields to formData
      for (const key in editedItem) {
        if (key !== '_id' && key !== 'filters' && key !== 'image') {
          formData.append(key, editedItem[key]);
        }
      }

      // Conditionally append the _id for updates
      if (editedItem._id) {
        formData.append('_id', editedItem._id);
      }

      // Handle image if it was uploaded
      if (imageFile) {
        formData.append('image', imageFile);
      }

      // Prepare filters data
      const filtersData = editedItem.filters.map((filter) => {
        const modifiedFilter = modifiedFilters[filter._id] || filter;
        return {
          ...modifiedFilter,
          options: modifiedFilter.options.map(([name, price]) => [
            name,
            price.toString(),
          ]), // Ensure options are arrays
        };
      });

      formData.append('filters', JSON.stringify(filtersData));

      // Determine if it's a new item or an update
      const isNewItem = !editedItem._id;

      // Call parent's onSave and pass the data
      onSave(formData, isNewItem, modifiedFilters);

      // Reset state after passing the data to the parent
      setModifiedFilters({});
      setErrors({});
      onClose(); // Close the popup
    } else {
      console.log('Form is invalid, show errors');
    }
  };

  const sortedFilters = editedItem.filters
    ? [...editedItem.filters].sort((a, b) => a.displayOrder - b.displayOrder)
    : [];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          position: isMobile ? 'absolute' : 'relative',
          bottom: isMobile ? 0 : 'auto',
          width: '100%',
          margin: 0,
          borderRadius: isMobile ? '16px 16px 0 0' : '16px',
          maxHeight: '85vh',
          display: 'flex',
          flexDirection: 'column',
          overflow: isMobile ? 'hidden' : 'auto',
        },
      }}
    >
      <DialogContent
        sx={{
          padding: '0 !important',
          overflowY: 'auto',
          direction: 'rtl',
          flexGrow: 1,
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-track': {
            display: 'none',
          },
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{
            position: 'absolute',
            left: 26,
            top: 16,
            fontSize: '20px',
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            borderRadius: '50%',
            padding: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          onClick={handleImageClick}
          style={{
            width: '100%',
            height: '50vw',
            maxHeight: '300px',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <img
            src={editedItem.image || ImagePlaceholder}
            alt={editedItem.image ? 'Menu Item' : 'Default Food Truck Image'}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          {!editedItem.image && (
            <Box
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                color: 'white',
                fontSize: '1.2rem',
              }}
            >
              <Typography
                variant="caption"
                sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}
              >
                לחץ להעלאת תמונה
              </Typography>
            </Box>
          )}
        </Box>
        <div style={{ padding: '0 1rem' }}>
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/*"
          />
          <Box sx={{ display: 'grid' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between', // Distribute space between items
                direction: 'rtl',
              }}
            >
              <EditableField
                value={editedItem.name}
                onChange={(value) => handleInputChange('name', value)}
                prompt="שם המוצר"
                sx={{
                  fontSize: '2.125rem', // same as h4
                  fontWeight: 700,
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column', // Arrange switches in a column
                  alignItems: 'flex-start', // Align items to the start of the column
                  minWidth: '9rem', // Add fixed minimum width
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={editedItem.isActive ?? true} // Default to true if item.isActive is undefined
                      onChange={handleToggle}
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          color: theme.palette.grey[200], // Default color when not checked
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: theme.palette.primary.main,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: theme.palette.primary.main,
                          },
                      }}
                    />
                  }
                  label={editedItem.isActive ? 'הצג בתפריט' : 'אל תציג בתפריט'}
                  labelPlacement="top"
                  sx={{ marginLeft: '1rem', width: '100%' }} // Adjust margin and width as needed
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={editedItem.isAvailable ?? true} // Default to true if item.isAvailable is undefined
                      onChange={handleAvailableToggle}
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          color: theme.palette.grey[200], // Default color when not checked
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                          color: theme.palette.primary.main,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                          {
                            backgroundColor: theme.palette.primary.main,
                          },
                      }}
                    />
                  }
                  label={editedItem.isAvailable ? 'זמין' : 'לא זמין'}
                  labelPlacement="top"
                  sx={{ marginLeft: '1rem', width: '100%' }} // Adjust margin and width as needed
                />
              </Box>
            </Box>
            {errors.name && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontSize: '1rem' }}
              >
                {errors.name}
              </Typography>
            )}
            <EditableField
              value={editedItem.description}
              onChange={(value) => handleInputChange('description', value)}
              prompt="תיאור המוצר"
              sx={{
                fontSize: '1.2rem',
                fontWeight: 400,
              }}
            />
            <EditableField
              value={editedItem.price}
              onChange={(value) => handleInputChange('price', value)}
              prompt="הכנס מחיר"
              variant="menuPricePopup"
              isPrice={true}
              style={{
                fontSize: theme.typography.menuPricePopup.fontSize,
                color: theme.palette.primary.main,
                fontWeight: theme.typography.menuPricePopup.fontWeight,
              }}
            />
            {errors.price && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontSize: '1rem' }}
              >
                {errors.price}
              </Typography>
            )}
            <CategoryDropdown
              categories={categories}
              style={{ zIndex: 1300 }}
              selectedCategory={editedItem.categoryId}
              onCategoryChange={(value) =>
                handleInputChange('categoryId', value)
              }
            />
            {errors.categoryId && (
              <Typography
                color="error"
                variant="caption"
                sx={{ fontSize: '1rem' }}
              >
                {errors.categoryId}
              </Typography>
            )}
          </Box>
          <Divider
            orientation="horizontal"
            variant="middle"
            sx={{ margin: '1rem 0' }}
          />
          <Typography variant="h6" gutterBottom>
            מסננים
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1rem',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setIsAddFilterDialogOpen(true)}
              sx={{
                marginBottom: '1rem',
                padding: '0.5rem',
                marginLeft: '1rem',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgb(230 230 230)'
                    : 'inherit',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgb(240 240 240)'
                      : 'inherit',
                },
              }}
            >
              הוספת מסנן
              <AddIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
            </Button>

            <Button
              variant="outlined"
              onClick={handleEditOrderClick}
              sx={{
                marginBottom: '1rem',
                padding: '0.5rem',
                marginLeft: '1rem',
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgb(230 230 230)'
                    : 'inherit',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgb(240 240 240)'
                      : 'inherit',
                },
              }}
            >
              עריכת סדר תצוגת מסננים
              <DragIndicator
                fontSize="small"
                style={{ marginRight: '0.5rem' }}
              />
            </Button>
          </div>

          {isFilterOrderPopupOpen && (
            <FilterOrderPopup
              open={isFilterOrderPopupOpen}
              onClose={handleFilterOrderClose}
              filters={sortedFilters}
              onUpdateFilters={handleUpdateFiltersOrder}
            />
          )}
          {sortedFilters.length > 0 ? (
            sortedFilters.map((filter) => (
              <Grid flexDirection={'column'} key={filter._id} mb={3}>
                <FormControl
                  component="fieldset"
                  key={filter._id}
                  style={{
                    width: '100%',
                  }}
                >
                  <FormLabel
                    component="legend"
                    sx={{
                      color: 'inherit',
                      '&.Mui-focused': {
                        color: 'inherit',
                      },
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton
                        onClick={() => handleDeleteFilter(filter._id)}
                        sx={{
                          color: 'red',
                          alignSelf: 'flex-end',
                          padding: '0', // Remove padding
                          marginLeft: '0.2rem',
                        }}
                      >
                        <DeleteForeverIcon sx={{ fontSize: '2rem' }} />
                      </IconButton>
                      <IconButton onClick={() => handleEditFilter(filter)}>
                        <EditIcon />
                      </IconButton>
                      <Typography variant="menuName">{filter.name}</Typography>
                    </Box>
                  </FormLabel>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: '0.5rem',
                    }}
                  >
                    {filter.options && Array.isArray(filter.options) ? (
                      filter.options.map((option, index) => {
                        // Check if the option is a valid array with two elements
                        if (Array.isArray(option) && option.length === 2) {
                          const [optionName, optionPrice] = option;
                          return (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                direction: 'ltr',
                              }}
                            >
                              <div>
                                <Typography
                                  variant="descMenuPopup"
                                  color="text.secondary"
                                  style={{
                                    flex: 1,
                                    textAlign: 'left',
                                  }}
                                >
                                  {optionPrice}
                                </Typography>
                              </div>
                              <FormControlLabel
                                control={<Checkbox disabled />}
                                label={
                                  <Typography
                                    variant="descMenuPopup"
                                    color="text.secondary"
                                    style={{
                                      width: '100%',
                                      textAlign: 'right',
                                    }}
                                  >
                                    {optionName}
                                  </Typography>
                                }
                                labelPlacement="start"
                                sx={{
                                  margin: '0',
                                  flexGrow: 1,
                                  justifyContent: 'flex-start',
                                  textAlign: 'right',
                                }}
                              />
                            </div>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <Typography variant="caption" color="error">
                        No options available
                      </Typography>
                    )}
                  </div>
                </FormControl>
              </Grid>
            ))
          ) : (
            <Typography>אין מסננים למוצר זה</Typography>
          )}
        </div>
      </DialogContent>

      {/* Testing quantityInStock  */}
      {manageStock && (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      direction: 'rtl',
      padding: '0.5rem',
      height: '2rem',
      maxHeight: '2rem',
      margin: 0,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        marginRight: '0.15rem',
        fontWeight: 'normal',
        marginLeft: 0,
        minWidth: 'fit-content',
        fontSize: '1.25rem',
        lineHeight: '2rem',
      }}
    >
      כמות במלאי:
    </Typography>
    <Box
      sx={{
        width: '2.8rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '1.75rem',
        maxHeight: '1.75rem',
        position: 'relative',
        marginRight: '-1.5rem',
      }}
    >
      <EditableField
        value={editedItem.quantityInStock}
        prompt="0"
        onChange={(value) => handleInputChange('quantityInStock', value)}
        isNumeric={true}
        sx={{
          position: 'absolute',
          width: '100%',
          fontWeight: 'bold',
          fontSize: '1.5rem',
          padding: 0,
          margin: 0,
          textAlign: 'center',
          lineHeight: '1.75rem',
          height: '1.75rem',
          color: theme.palette.secondary.main,
          '& input': {
            padding: 0,
            margin: 0,
            lineHeight: '1.75rem',
            height: '1.75rem',
            textAlign: 'center',
            fontSize: '1.5rem',
            width: '100%',
            color: theme.palette.secondary.main,
          },
        }}
      />
    </Box>
  </Box>
)}
      {/* End of testing quantityInStock  */}

      <DialogActions
        style={{
          padding: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 'auto',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{
            width: '100%',
            color: '#fff',
            height: '48px',
            fontSize: '1rem',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          }}
        >
          <Typography variant="menuPricePopup">שמור שינויים</Typography>
        </Button>
      </DialogActions>
      {isCropperOpen && (
        <Dialog
          open={isCropperOpen}
          onClose={() => setIsCropperOpen(false)}
          fullWidth
          maxWidth="sm"
          sx={{
            '& .MuiDialog-paper': {
              maxHeight: '90vh', // Limit the dialog height to 90% of the viewport height
            },
          }}
        >
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0',
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setIsCropperOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="titlePopup"
                sx={{ flexGrow: 1, textAlign: 'right' }}
              >
                מומלץ למקם את המוצר בתור העיגול המסומן
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              maxHeight: 'calc(90vh - 64px)', // Ensure space for title and actions
            }}
          >
            <ImageCropper
              src={croppedImage}
              onCrop={handleCrop}
              aspectRatio={2 / 1}
            />
          </DialogContent>
        </Dialog>
      )}
      <AddFilterPopup
        open={isAddFilterDialogOpen}
        onClose={() => {
          setIsAddFilterDialogOpen(false);
          setEditingFilter(null);
        }}
        onAddFilter={handleOnSaveFilter}
        editingFilter={editingFilter}
      />
    </Dialog>
  );
};

export default MenuItemPopup;
