import React, { useState, useEffect, useRef } from 'react';
import { IconButton, ClickAwayListener, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/styles';

const StyledTypography = styled(Typography)(({ theme }) => ({
  cursor: 'text',
  '&:hover': {
    background: 'rgba(0, 0, 0, 0.04)',
  },
}));

const EditableInput = styled('input')(({ theme }) => ({
  background: 'none',
  border: 'none',
  padding: 0,
  outline: 'none',
  width: '100%',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  color: 'inherit',
  textAlign: 'inherit',
  direction: 'inherit',
  '&::placeholder': {
    opacity: 0.5,
  },
}));

const EditableField = ({
  value,
  onChange,
  prompt,
  style,
  isPrice = false,
  isNumeric = false,
  variant,
  sx,
  inputRef: externalInputRef,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(
    isPrice ? value.toFixed(2) : value
  );
  const internalInputRef = useRef(null);
  const inputRef = externalInputRef || internalInputRef;
  const incrementInterval = useRef(null);
  const decrementInterval = useRef(null);
  const speedUpTimeout = useRef(null);
  const intervalTime = useRef(100);
  const stepSize = useRef(0.1);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditing, inputRef]);

  // Update tempValue when external value changes
  useEffect(() => {
    setTempValue(isPrice ? value.toFixed(2) : value);
  }, [value, isPrice]);

  useEffect(() => {
    const handleMouseUp = () => {
      stopIncrement();
      stopDecrement();
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
      clearInterval(incrementInterval.current);
      clearInterval(decrementInterval.current);
      clearTimeout(speedUpTimeout.current);
    };
  }, []);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    let newValue = e.target.value;

    if (isNumeric) {
      // Allow only numbers and prevent negative values
      newValue = newValue.replace(/[^\d]/g, '');
    } else if (isPrice) {
      // Keep existing price validation
      newValue = newValue.replace(/[^\d.]/g, '');
    }
    setTempValue(newValue);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (isPrice) {
      if (tempValue === '') {
        setTempValue('0.00');
        onChange(0);
      } else {
        const formattedValue = parseFloat(tempValue).toFixed(2);
        setTempValue(formattedValue);
        onChange(parseFloat(formattedValue) || 0);
      }
    } else {
      onChange(tempValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  const handleIncrement = () => {
    setTempValue((prevValue) => {
      const newValue = (parseFloat(prevValue) + stepSize.current).toFixed(2);
      onChange(parseFloat(newValue));
      return newValue;
    });
  };

  const handleDecrement = () => {
    setTempValue((prevValue) => {
      const newValue = (parseFloat(prevValue) - stepSize.current).toFixed(2);
      onChange(parseFloat(newValue));
      return newValue;
    });
  };

  const startIncrement = () => {
    handleIncrement();
    intervalTime.current = 100;
    stepSize.current = 0.1;
    speedUpTimeout.current = setTimeout(speedUpIncrement, 1000);
  };

  const stopIncrement = () => {
    clearInterval(incrementInterval.current);
    clearTimeout(speedUpTimeout.current);
    intervalTime.current = 100;
    stepSize.current = 0.1;
  };

  const speedUpIncrement = () => {
    clearInterval(incrementInterval.current);
    intervalTime.current = Math.max(1, intervalTime.current * 0.9);
    stepSize.current = Math.min(1, stepSize.current + 0.1);
    incrementInterval.current = setInterval(
      handleIncrement,
      intervalTime.current
    );
    speedUpTimeout.current = setTimeout(speedUpIncrement, 50);
  };

  const startDecrement = () => {
    handleDecrement();
    intervalTime.current = 100;
    stepSize.current = 0.1;
    speedUpTimeout.current = setTimeout(speedUpDecrement, 1000);
  };

  const stopDecrement = () => {
    clearInterval(decrementInterval.current);
    clearTimeout(speedUpTimeout.current);
    intervalTime.current = 100;
    stepSize.current = 0.1;
  };

  const speedUpDecrement = () => {
    clearInterval(decrementInterval.current);
    intervalTime.current = Math.max(1, intervalTime.current * 0.9);
    stepSize.current = Math.min(1, stepSize.current + 0.1);
    decrementInterval.current = setInterval(
      handleDecrement,
      intervalTime.current
    );
    speedUpTimeout.current = setTimeout(speedUpDecrement, 50);
  };

  const combinedStyle = {
    ...style,
    ...sx,
  };

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isPrice && !isEditing && (
          <IconButton
            size="small"
            onMouseDown={startDecrement}
            onTouchStart={startDecrement}
            onMouseUp={stopDecrement}
            onTouchEnd={stopDecrement}
          >
            <RemoveIcon />
          </IconButton>
        )}
        {isEditing ? (
          <EditableInput
            ref={inputRef}
            value={tempValue}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder={prompt}
            style={combinedStyle}
          />
        ) : (
          <StyledTypography
            onClick={handleClick}
            style={combinedStyle}
            color={isPrice ? 'primary' : 'inherit'}
          >
            {isPrice
              ? `₪${parseFloat(tempValue).toFixed(2)}`
              : tempValue || <span style={{ opacity: 0.5 }}>{prompt}</span>}
          </StyledTypography>
        )}
        {isPrice && !isEditing && (
          <IconButton
            size="small"
            onMouseDown={startIncrement}
            onTouchStart={startIncrement}
            onMouseUp={stopIncrement}
            onTouchEnd={stopIncrement}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default EditableField;
