import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Popup from './MenuItemPopUpDash';
import ConfirmDialog from '../core/confirmDialog';
import { removeMenuItem } from '../../features/menu/managerMenuThunks';
import { selectCategories } from '../../features/category/CategorySlice';

const MenuItemDash = ({ item, onSaveEditItem }) => {
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const categories = useSelector(selectCategories);
  const theme = useTheme();

  const handleEditClick = (event) => {
    event.stopPropagation();
    setPopupOpen(true);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const handleRemoveClick = (event) => {
    event.stopPropagation();
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  const handleConfirmDelete = async () => {
    try {
      await dispatch(removeMenuItem(item._id)).unwrap();
      setConfirmDeleteOpen(false);
    } catch (error) {
      console.error('Failed to delete item:', error);
    }
  };

  const handleSave = async (
    updatedItem,
    isNewItem,
    newFilters,
    modifiedFilters
  ) => {
    onSaveEditItem(updatedItem, isNewItem, newFilters, modifiedFilters);
  };

  return (
    <>
      <Card
        sx={{
          height: '120px',
          borderRadius: '10px',
          boxShadow:
            theme.palette.mode === 'dark'
              ? '0 5px 20px rgba(255, 255, 255, 0.1)'
              : '0 5px 20px rgba(0, 0, 0, 0.1)',
          width: '100%',
          bgcolor: theme.palette.background.paper,
        }}
      >
        <CardContent
          sx={{
            padding: 0,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="stretch"
            justifyContent="space-between"
            style={{ height: '100%' }}
          >
            <Grid
              size={{ xs: 7 }}
              sx={{
                paddingRight: '1rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flexGrow: 1, marginLeft: '0.7rem' }}>
                <Typography variant="menuName" mb={0} color="text.primary">
                  {item.name}
                </Typography>
                <Typography
                  variant="menuDesc"
                  sx={{
                    wordWrap: 'break-word',
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    lineHeight: 1.2,
                    color: 'text.secondary',
                  }}
                >
                  {item.description}
                </Typography>
              </div>
              <Typography variant="menuPrice" color="primary" mb={1}>
                {`₪${item.price.toFixed(2)}`}
              </Typography>
            </Grid>
            {item.image && (
              <Grid
                size={{ xs: 5 }}
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  height: '120px',
                }}
              >
                <img
                  src={item.image}
                  alt="Menu Item"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                />
                <div
                  style={{
                    position: 'absolute',
                    top: '5px',
                    left: '5px',
                    display: 'flex',
                    gap: '2px',
                    borderRadius: '0.5rem',
                    padding: '2px',
                  }}
                >
                  <IconButton
                    onClick={handleEditClick}
                    sx={{
                      backgroundColor: theme.palette.success.main,
                      color: theme.palette.common.white,
                      borderRadius: '0 0.5rem 0.5rem 0',
                      padding: '5px',
                      '&:hover': {
                        backgroundColor: theme.palette.success.dark,
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={handleRemoveClick}
                    sx={{
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.common.white,
                      borderRadius: '0.5rem 0 0 0.5rem',
                      padding: '5px',
                      '&:hover': {
                        backgroundColor: theme.palette.error.dark,
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Popup
        open={popupOpen}
        onClose={handleClose}
        item={item}
        onSave={handleSave}
        categories={categories}
      />
      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
        onConfirm={handleConfirmDelete}
        title="אישור מחיקה"
        description="האם אתה בטוח שאתה רוצה למחוק מוצר זה?"
      />
    </>
  );
};

export default MenuItemDash;
