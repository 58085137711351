// used for the drag and drop functionality of the filters in the FilterOrderPopup component

import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragIndicator } from '@mui/icons-material'; // Import the DragIndicator icon
import './FilterOrderPopup.css';

const ItemType = 'FILTER';

const FilterItem = ({ filter, index, moveFilter }) => {
  const [{ isDragging }, ref] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveFilter(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className={`filter-item ${isDragging ? 'dragging' : ''}`}
      style={{display: 'flex', alignItems: 'center',
        justifyContent: 'space-between', padding: '10px', cursor: 'move'
      }}
    >
      {filter.name}
      <DragIndicator className="drag-icon"/>
    </div>
  );
};

export default FilterItem;