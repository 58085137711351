import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Container,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from '@mui/material/Link';
import { alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectConfiguration } from '../../features/configuration/configurationSlice';

const Footer = ({ workingHours = [] }) => {
  const theme = useTheme();
  const configurations = useSelector(selectConfiguration);
  const config = configurations[0] || {};
  const { phone, email, address, facebook, instagram, placeName } = config;

  const currentYear = new Date().getFullYear();
  const footerBgColor =
    theme.palette.mode === 'light'
      ? alpha(theme.palette.grey[200], 0.8)
      : alpha(theme.palette.grey[900], 0.5);

  const daysInHebrew = [
    'ראשון',
    'שני',
    'שלישי',
    'רביעי',
    'חמישי',
    'שישי',
    'שבת',
  ];

  const formatHours = (shifts) => {
    if (!shifts || shifts.length === 0) return 'סגור'; // Closed if no shifts

    // Display closeTime on the left and openTime on the right
    return shifts
      .map((shift) => `${shift.closeTime} - ${shift.openTime}`)
      .join(', ');
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: footerBgColor,
        color: 'text.primary',
        py: 3,
        mt: 'auto',
        backdropFilter: 'blur(8px)',
      }}
    >
      <Container maxWidth="lg">
        <>
          <Grid container spacing={4} direction="row-reverse">
            {/* Contact Info */}
            <Grid
              size={{ xs: 12, md: 4 }}
              sx={{ direction: 'rtl', textAlign: 'right' }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'grey.800' : 'grey.100',
                }}
              >
                צור קשר
              </Typography>
              <Box
                sx={{
                  display: 'inline-flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'flex-start',
                  color: 'text.secondary',
                }}
              >
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <PhoneIcon fontSize="small" />
                  <Typography>{phone}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <EmailIcon fontSize="small" />
                  <Typography>{email}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <LocationOnIcon fontSize="small" />
                  <Typography>{address}</Typography>
                </Box>
              </Box>
            </Grid>

            {/* Opening Hours */}
            <Grid
              size={{ xs: 12, md: 4 }}
              sx={{ direction: 'rtl', textAlign: 'right' }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'grey.800' : 'grey.100',
                }}
              >
                שעות פעילות
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  color: 'text.secondary',
                }}
              >
                {daysInHebrew.map((day, index) => (
                  <Typography key={index}>
                    {day}: {formatHours(workingHours[index])}
                  </Typography>
                ))}
              </Box>
            </Grid>

            {/* Social Links */}
            <Grid
              size={{ xs: 12, md: 4 }}
              sx={{ direction: 'rtl', textAlign: 'right' }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'grey.800' : 'grey.100',
                }}
              >
                עקבו אחרינו
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  justifyContent: 'flex-start',
                }}
              >
                {instagram && (
                  <IconButton
                    component={Link}
                    href={instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'text.primary',
                      },
                    }}
                  >
                    <InstagramIcon />
                  </IconButton>
                )}
                {facebook && (
                  <IconButton
                    component={Link}
                    href={facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'text.primary',
                      },
                    }}
                  >
                    <FacebookIcon />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
        <Box
          sx={{
            mt: 4,
            pt: 2,
            borderTop: 1,
            borderColor: 'divider',
            textAlign: 'center',
            direction: 'rtl',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              opacity: 0.8,
            }}
          >
            © {currentYear} {placeName}. כל הזכויות שמורות.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
