import { createTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';
import { grey } from '@mui/material/colors';

// Default configuration values
const defaultConfig = {
  primaryColor: '#00796B',
  secondaryColor: '#a5d6a7',
  fontFamily: "'Assistant', sans-serif",
};

// Updated function to get configuration from Redux state
const getConfigFromState = (configurations) => {
  if (!configurations || configurations.length === 0) {
    return defaultConfig;
  }

  // Get the first configuration object since it contains all our settings
  const configObj = configurations;

  const config = {
    primaryColor: configObj.primaryColor || defaultConfig.primaryColor,
    secondaryColor: configObj.secondaryColor || defaultConfig.secondaryColor,
    fontFamily: configObj.fontFamily || defaultConfig.fontFamily,
  };

  return config;
};

export const createAppTheme = (mode, configurations) => {
  const isDarkMode = mode === 'dark';
  const config = getConfigFromState(configurations);
  return createTheme(
    {
      palette: {
        mode,
        primary: {
          main: config.primaryColor,
        },
        secondary: {
          main: config.secondaryColor,
        },
        background: {
          default: isDarkMode ? '#121212' : '#ffffff',
          paper: isDarkMode ? '#1e1e1e' : '#ffffff',
        },
        text: {
          primary: isDarkMode ? '#ffffff' : '#000000',
          secondary: isDarkMode ? grey[300] : grey[700],
        },
      },
      typography: {
        fontFamily: config.fontFamily,
        menuName: {
          fontSize: 'clamp(1.35rem, 2.5vw, 1.4rem)',
          fontWeight: '500',
        },
        menuDesc: {
          fontSize: 'clamp(1.15rem, 2vw, 1.2rem)',
          fontWeight: 'Light',
          color: grey[700],
        },
        menuTitle: {
          fontSize: 'clamp(1.6rem, 2vw, 1.8rem)',
          fontWeight: 500,
          textAlign: 'right',
        },
        menuPrice: {
          fontSize: 'clamp(1.15rem, 2vw, 1.1rem)',
          fontWeight: 'Medium',
        },
        navbar: {
          fontSize: 'clamp(1.15rem, 2vw, 1.2rem)',
          fontWeight: 'Medium',
        },
        titleMenuPopup: {
          fontSize: 'clamp(1.85rem, 2vw, 1.9rem)',
          fontWeight: 'bold',
        },
        descMenuPopup: {
          fontSize: 'clamp(1.15rem, 2vw, 1.2rem)',
          fontWeight: 'regular',
        },
        menuPricePopup: {
          fontSize: 'clamp(1.15rem, 2vw, 1.2rem)',
          fontWeight: 'light',
        },
        titlePopup: {
          fontSize: 'clamp(1rem, 3.5vw, 1.4rem)',
          fontWeight: '500',
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              fontFamily: config.fontFamily,
              color: (theme) => theme.palette.text.primary,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-root': {
              transformOrigin: 'right !important',
              left: 'inherit !important',
              right: '1.75rem !important',
              fontSize: '0.875rem',
              color: grey[700],
              fontWeight: 400,
              overflow: 'unset',
            },
            '& .MuiInputLabel-shrink': {
              transformOrigin: 'right !important',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                textAlign: 'right',
              },
            },
            '& .MuiInputBase-input': {
              textAlign: 'right',
            },
          },
        },
      },
    },
    {
      customStyles: {
        gradientBackground: (theme) => ({
          bgcolor: theme.palette.background.default,
          backgroundImage: `
          radial-gradient(18% 28% at 24% 50%, ${alpha(
            theme.palette.primary.main,
            theme.palette.mode === 'dark' ? 0.03 : 0.1
          )} 2%, transparent 100%),
          radial-gradient(18% 28% at 18% 71%, ${alpha(
            '#FFFFFF',
            theme.palette.mode === 'dark' ? 0.008 : 0.1
          )} 4%, transparent 100%),
          radial-gradient(70% 53% at 36% 76%, ${alpha(
            theme.palette.primary.main,
            theme.palette.mode === 'dark' ? 0.03 : 0.1
          )} 2%, transparent 100%),
          radial-gradient(42% 53% at 34% 72%, ${alpha(
            '#FFFFFF',
            theme.palette.mode === 'dark' ? 0.008 : 0.1
          )} 7%, transparent 100%),
          radial-gradient(18% 28% at 35% 87%, ${alpha(
            '#FFFFFF',
            theme.palette.mode === 'dark' ? 0.008 : 0.1
          )} 7%, transparent 100%),
          radial-gradient(31% 43% at 7% 98%, ${alpha(
            '#FFFFFF',
            theme.palette.mode === 'dark' ? 0.008 : 0.1
          )} 24%, transparent 100%),
          radial-gradient(21% 37% at 72% 23%, ${alpha(
            theme.palette.primary.main,
            theme.palette.mode === 'dark' ? 0.03 : 0.1
          )} 24%, transparent 100%),
          radial-gradient(35% 56% at 91% 74%, ${alpha(
            '#FFFFFF',
            theme.palette.mode === 'dark' ? 0.008 : 0.1
          )} 9%, transparent 100%)
        `,
          backgroundBlendMode: 'normal',
        }),
      },
    }
  );
};

// Create themes with empty configuration array as default
export const lightTheme = createAppTheme('light', []);
export const darkTheme = createAppTheme('dark', []);

// Remove localStorage related functions since we're using Redux now
export default lightTheme;
