import React, { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Box } from '@mui/material';

const ImageCropper = ({
  src,
  onCrop,
  aspectRatio = 2 / 1,
  showCircleOverlay = true,
}) => {
  const [cropper, setCropper] = useState(null);

  const cropImage = () => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const file = new File([blob], 'cropped_image.jpeg', {
          type: 'image/jpeg',
        });
        onCrop(file);
      }, 'image/jpeg');
    }
  };

  return (
    <Box
      className="cropper-container"
      sx={{
        p: 2,
        position: 'relative',
        height: 'auto',
        maxHeight: '500px',
        // Only apply circle overlay styles if showCircleOverlay is true
        ...(showCircleOverlay && {
          '& .cropper-crop-box::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '70%',
            height: '90%',
            border: '3px dotted #000',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
            zIndex: 1,
          },
        }),
      }}
    >
      <Cropper
        style={{ width: '100%', maxHeight: '400px' }}
        aspectRatio={aspectRatio}
        initialAspectRatio={aspectRatio}
        src={src}
        viewMode={1}
        guides={false}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        dragMode="move"
        cropBoxMovable={false}
        cropBoxResizable={false}
        center={true}
        scalable={true}
        zoomable={true}
        minContainerWidth={0}
        minContainerHeight={0}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <Button
        onClick={cropImage}
        fullWidth
        variant="contained"
        color="primary"
        sx={{ mt: 2, fontSize: '1rem' }}
      >
        חתוך תמונה
      </Button>
    </Box>
  );
};

export default ImageCropper;
