import React, { useState, useEffect } from 'react';
import { Popover, Typography, Button, CircularProgress } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DragIndicator, Add as AddIcon } from '@mui/icons-material';
import { useDrag, useDrop } from 'react-dnd';
import './CategoryPopup.css';
import { Edit } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const DraggableCategory = ({
  category,
  index,
  moveCategory,
  onCategoryNameChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [categoryName, setCategoryName] = useState(category.name);
  const theme = useTheme();

  const [, drag] = useDrag({
    type: 'category',
    item: { id: category._id, index },
  });

  const [, drop] = useDrop({
    accept: 'category',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveCategory(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  useEffect(() => {
    setCategoryName(category.name);
  }, [category.name]);

  const handleEdit = () => setIsEditing(true);

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onCategoryNameChange(category.id, categoryName);
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="draggable-category"
      style={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
      }}
    >
      {isEditing ? (
        <input
          type="text"
          value={categoryName}
          onChange={handleNameChange}
          onBlur={handleBlur}
          autoFocus
          className="editable-input"
          style={{ borderColor: theme.palette.primary.main }}
        />
      ) : (
        <span className="category-text">
          {categoryName}
          <Edit
            className="edit-icon"
            onClick={handleEdit}
            style={{
              fontSize: '0.9rem',
              marginRight: '0.4rem',
              color: theme.palette.primary.main,
            }}
          />
        </span>
      )}
      <DragIndicator
        className="drag-icon"
        style={{ color: theme.palette.text.secondary }}
      />
    </div>
  );
};

const CategoryPopup = ({
  categories,
  onCategoriesUpdate,
  onSaveCategories,
  onCancelChanges,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [localCategories, setLocalCategories] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0) {
      const sortedCategories = categories.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      setLocalCategories([...sortedCategories]);
    }
  }, [categories]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const moveCategory = (fromIndex, toIndex) => {
    const updatedCategories = [...localCategories];
    const [movedCategory] = updatedCategories.splice(fromIndex, 1);
    updatedCategories.splice(toIndex, 0, movedCategory);

    updatedCategories.forEach((category, index) => {
      category.displayOrder = index + 1;
    });

    setLocalCategories(updatedCategories);
    onCategoriesUpdate(updatedCategories);
    setHasChanges(true);
  };

  const handleCategoryNameChange = (categoryId, newName) => {
    const updatedCategories = localCategories.map((category) =>
      category.id === categoryId ? { ...category, name: newName } : category
    );
    setLocalCategories(updatedCategories);
    onCategoriesUpdate(updatedCategories);
    setHasChanges(true);
  };

  const handleSave = () => {
    onSaveCategories(localCategories);
    handleClose();
  };

  const handleCancel = () => {
    if (hasChanges) {
      onCancelChanges();
    }
    handleClose();
  };

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const handleAddCategory = () => {
    const newCategory = {
      id: `temp_${Date.now()}`,
      name: 'קטגוריה חדשה',
      displayOrder: localCategories.length + 1,
    };
    const updatedCategories = [...localCategories, newCategory];
    setLocalCategories(updatedCategories);
    onCategoriesUpdate(updatedCategories);
    setHasChanges(true);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={
          <Edit
            style={{
              fontSize: '1rem',
            }}
          />
        }
        className="reorder-button"
        style={{
          backgroundColor: theme.palette.primary.main,
          minWidth: '200px',
          borderRadius: '0.5rem',
          height: '3rem',
        }}
      >
        עריכת/הוספת קטגוריות
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            className: 'category-popover-paper',
            style: {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
          },
        }}
      >
        <Typography
          variant="h6"
          className="popover-title"
          style={{ marginBottom: '0', color: theme.palette.text.primary }}
        >
          שינוי סדר קטגוריות
        </Typography>
        <Typography
          variant="body2"
          style={{
            marginBottom: '0.5rem',
            textAlign: 'right',
            color: theme.palette.text.secondary,
          }}
        >
          לשינוי סדר תצוגת הקטגוריות יש לגרור את הקטגוריה למקום הרצוי
        </Typography>
        <div className="popover-content">
          {isLoading ? (
            <div className="loading-container">
              <CircularProgress />
            </div>
          ) : (
            <DndProvider
              backend={isTouchDevice() ? TouchBackend : HTML5Backend}
            >
              {localCategories.map((category, index) => (
                <DraggableCategory
                  key={category._id}
                  category={category}
                  index={index}
                  moveCategory={moveCategory}
                  onCategoryNameChange={handleCategoryNameChange}
                />
              ))}
            </DndProvider>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleAddCategory}
            className="add-category-button"
            style={{
              backgroundColor: theme.palette.background.paper,
              fontSize: '0.875rem',
              border: `1px solid ${theme.palette.primary.main}`,
              color: theme.palette.primary.main,
              width: '100%',
              boxShadow: 'none',
            }}
            startIcon={<AddIcon />}
          >
            הוסף קטגוריה חדשה
          </Button>
        </div>
        <div className="button-container">
          <Button
            onClick={handleCancel}
            variant="outlined"
            className="cancel-button"
            style={{
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }}
          >
            ביטול
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            className="save-button"
            disabled={isLoading}
          >
            שמירה
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default CategoryPopup;
